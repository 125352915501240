<script setup>
import {ref, computed, watch, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import markdownit from 'markdown-it'
import {BookOpenIcon, ClockIcon, UserCircleIcon, FireIcon, CheckIcon, 
    PlusIcon, StarIcon} from '@heroicons/vue/24/solid'

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

import Header from './Header.vue'
import Tooltip from './Tooltip.vue'

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();
const route = useRoute();
var slug;

const props = defineProps({
});

const extra_data = reactive({
    posts: [],
});


onMounted(() => {
    get_lessons();
});

watch( () => route.query, (new_query, old_query) =>{
    get_lessons();
});

async function get_lessons(){
    var url = app_server + `/lesson/`;
    if(route.query){
        const params = new URLSearchParams(route.query);
        console.log(route.query, params);
        url += "?" + params.toString();
    }
    console.log(url)

    const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const data = await response.json();
    if(data.status == "success"){
        extra_data.lessons = data.lessons;
    }
    
    console.log(data);
}

function can_edit(lesson){
    const user_id = userStore.info.user_id;
    return lesson.author_id == user_id || lesson.editors.includes(user_id);
}

</script>

<template>
    <Header />
    <div class="container mx-auto">
    <h1 class="text-4xl font-semibold text-center mt-8 mb-12 flex items-center justify-center gap-x-2">
        Lessons
        <router-link v-if="userStore.has_permission('lesson', 'create')"
            :to="{name: 'lesson-list', query: {status:'all'}}">
            (all)
        </router-link>
        <router-link v-if="userStore.has_permission('lesson', 'create')"
            :to="{name: 'lesson-create', params:{lesson_id: null}}"
        >
            <PlusIcon class="cursor-pointer hover:text-accent-3-color size-8" />
        </router-link>
    </h1>
    <div class="grid grid-cols-1 md:grid-cols-2 w-full gap-8">
        <div class="flex box rounded bg-main-2-color py-4 px-6 group relative transition-all" 
            v-for="lesson in extra_data.lessons">
            <div class="grow">
                <router-link :to="{name: 'lesson', params:{slug: lesson.slug}}">
                    <h2 class="text-2xl hover:text-accent-3-color">
                        {{ lesson.title }}
                    </h2>
                </router-link>
                <div class="flex gap-x-4 mt-3">
                    <div class="flex gap-x-1 items-center">
                        <BookOpenIcon class="w-4 h-4" />
                        <span class="text-right">{{ lesson.questions.length }}</span>
                    </div>
                    <div class="flex gap-x-1 items-center">
                        <UserCircleIcon class="w-4 h-4" />
                        <span class="text-right">{{ lesson.author.username }}</span>
                    </div>
                    <div class="flex gap-x-1 items-center">
                        <ClockIcon class="w-4 h-4" />
                        <span>{{ new Date(lesson.publish_time*1000).toDateString() }}</span>
                    </div>
                    <div class="flex gap-x-2 grow justify-end group-hover:visible invisible">
                        <Tooltip text="Percentage of lesson completions">
                            <span v-if="lesson.started > 0">
                                {{ (100 * lesson.finished / lesson.started).toFixed(0) }}%
                            </span>
                        </Tooltip>
                        <Tooltip text="Total number of stars earned on this lesson">
                            <span class="flex items-center">
                            {{ (lesson.stars > 1000) ? (lesson.stars/1000).toFixed(0) + "k" : lesson.stars }}
                            <StarIcon class="size-4" />
                            </span>
                        </Tooltip>
                    </div>
                </div>
                <div class="mt-4 text-lg font-thin overflow-y-hidden h-24 relative">
                    <div class="absolute bottom-0 w-full h-10 bg-gradient-to-t from-main-2-color" />
                    {{ lesson.summary }}
                </div>
                <div class="flex justify-center gap-x-4">
                    <router-link :to="{name: 'lesson', params:{slug: lesson.slug}}">
                    <button class="btn btn-blue h-12">
                        Start Lesson
                    </button>
                    </router-link>
                    <router-link v-if="can_edit(lesson)"
                        :to="{name: 'lesson-create', params:{lesson_id: lesson.lesson_id}}">
                        <button class="btn h-12">
                            Edit
                        </button>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<style scoped>
</style>


