<script setup>

import {ref, computed, reactive, onMounted, onUnmounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {UserCircleIcon, PuzzlePieceIcon, FireIcon, TrashIcon, CheckIcon, XMarkIcon, Bars3Icon} from '@heroicons/vue/24/solid'

import Header from './Header.vue'
import Board from './Board.vue'
import Leaderboard from './Leaderboard.vue'
import CreateMatch from './CreateMatch.vue'
import JoinMatch from './JoinMatch.vue'
import JoinTournament from './JoinTournament.vue'
import MatchList from './MatchList.vue'
import MatchInProgress from './MatchInProgress.vue'
import Tooltip from './Tooltip.vue'

import {useMatchStore } from '@/stores/matchstore.js'
const matchStore = useMatchStore();

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

import {useSSEStore } from '@/stores/ssestore.js'
const sseStore = useSSEStore();


const app_server = import.meta.env.VITE_APP_SERVER;

const router = useRouter();

const join_data = reactive({
    join_requests: {},
    joining_match: false,
    match_id: null,
});

const tournament_data = reactive({
    arena_list: [],
    upcoming_arena_list: [],
    tournament_list: [],
    join_tournament_id: null,
});

const extra_data = reactive({
    open_account_details:false,
    show_matchinprogress: true,
    show_arena: true,
});

const dailypuzzle_data = reactive({
    puzzle: {},
    equity: 0,
    percentage_correct: 0,
    solves: 0,
    solved: false,
})

onMounted(() => {
    get_daily_puzzle();
    setTimeout(() => update_wait_time(), 0);
    reload();
    setInterval(() => {
        update_wait_time();
    }, 1000);
});

onUnmounted(() => {
});

/**
 * Translates seconds into human readable format of seconds, minutes, hours, days, and years
 * https://stackoverflow.com/a/34270811
 * @param  {number} seconds The number of seconds to be processed
 * @return {string}         The phrase describing the amount of time
 */
function timeForHumans(seconds){
    var levels = [
        [Math.floor(seconds / 31536000), "Y"],
        [Math.floor((seconds % 31536000) / 86400), "D"],
        [Math.floor(((seconds % 31536000) % 86400) / 3600), "H"],
        [Math.floor((((seconds % 31536000) % 86400) % 3600) / 60), "M"],
        [(((seconds % 31536000) % 86400) % 3600) % 60, "S"],
    ];
    var returntext = "";
    var max_levels = 2;
    for (var i = 0, max = levels.length ; i < max && max_levels > 0; i++) {
        if ( levels[i][0] === 0 ) continue;
        returntext += " " + levels[i][0] + " " + (levels[i][0] === 1 ? levels[i][1].substr(0, levels[i][1].length-1): levels[i][1]);
        max_levels--;
    };
    return returntext.trim();
}

function countdown(seconds){
    if(seconds > 3600 * 72){
        return `${(seconds / (3600*24)).toFixed(0)} Days`
    }
    const hours = String( Math.floor(seconds / 3600).toFixed(0) ).padStart(2, "0");
    const minutes = String(Math.floor((seconds % 3600) / 60).toFixed(0)).padStart(2, "0");
    const secs = String(seconds % 60).padStart(2, "0");

    return `${hours}:${minutes}:${secs}`
}

async function reload(){
    if(await userStore.loadUser()){
        sseStore.connect();
        reload_arena_list();
        reload_tournament_list();
    }else{
        // we could not load the user, log out and redirect to the homepage
        messageStore.alertUser("User not logged in", "Please log in to create and join matches");
        userStore.logout();
        setTimeout(() => router.replace({name:"home"}), 2000);
    }
}

async function reload_arena_list(){
    const response = await fetch(app_server + "/arena/",{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    }).then(response => {
        if (response.status >= 400 && response.status < 600) {
          throw new Error("Bad response from server");
        }
        return response;    
    }).catch(error => {
        messageStore.alertUser("User not logged in", "Please log in to create and join matches", 5000);
        return error;
    });
    
    if(response instanceof Error){
        return;
    }
    
    const data = await response.json();
    
    tournament_data.arena_list.length = 0;
    tournament_data.upcoming_arena_list.length = 0;
    if(data.tournaments){
        tournament_data.arena_list.push(...data.tournaments);
        tournament_data.upcoming_arena_list.push(...data.upcoming);
    }
    tournament_data.arena_list.sort( (a, b) => (new Date(a.start)).getTime() - (new Date(b.start)).getTime() );
    tournament_data.upcoming_arena_list.sort( (a, b) => (new Date(a.start)).getTime() - (new Date(b.start)).getTime() );

    for(let tournament of data.tournaments){
        matchStore.matchqueue_queued[tournament.tournament_id] = tournament.users_queued;
    }

    console.log(tournament_data);

    update_wait_time();
}

async function get_tournaments(tournament_status = "inactive"){
    var url = app_server + `/tournament/?status=${tournament_status}`;

    const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const data = await response.json();

    if(data.status == "success"){
        return data.tournaments;
    }
    console.log("TOURNAMENT", data);
    
    return [];
}

async function reload_tournament_list(){
    get_tournaments("inactive").then( data => {
        tournament_data.tournament_list.push(...data);
        tournament_data.tournament_list.sort( (a,b) => a.status.localeCompare(b.status) );
        console.log(tournament_data.tournament_list);
    });

    get_tournaments("active").then( data => {
        data = data.filter( x => userStore.info.user_id in x.participants);
        for(let tournament of data){
            tournament.is_registered = true;
        }
        tournament_data.tournament_list.push(...data);
        tournament_data.tournament_list.sort( (a,b) => a.status.localeCompare(b.status) );
        console.log(tournament_data.tournament_list);
    });
}

function update_wait_time(){
    for(const tournament of tournament_data.upcoming_arena_list){
        const tournament_start = new Date(tournament.start_time);
        var wait_time = Math.floor((tournament_start - Date.now())/1000);
        
        wait_time = Math.max(0, wait_time);
        tournament.wait_time = countdown(wait_time);
        tournament.before = true;
    }

    for(const tournament of tournament_data.arena_list){
        var wait_until;
        const tournament_start = new Date(tournament.start);
        const tournament_end = new Date(tournament.stop);
        if(Date.now() < tournament_start){
            wait_until = tournament_start;
            tournament.before = true;
        }else{
            wait_until = tournament_end;
            tournament.before = false;
        }
        var wait_time = Math.floor((wait_until - Date.now())/1000);
        
        wait_time = Math.max(0, wait_time);
        tournament.wait_time = countdown(wait_time);
    }

    for(const tournament of tournament_data.tournament_list){
        var wait_until;
        const registration_ends = new Date(tournament.start_time*1000);
        // console.log(tournament.start_time, registration_ends);
        
        if(Date.now() < registration_ends){
            wait_until = registration_ends;
        }else{
            wait_until = 0;
        }
        
        var wait_time = Math.floor((wait_until - Date.now())/1000);
        
        wait_time = Math.max(0, wait_time);
        tournament.wait_time = countdown(wait_time);
    }
}


function get_daily_puzzle_stats(puzzle){
    if(puzzle == null){
        return "-";
    }
    const total = puzzle.correct + puzzle   .incorrect;

    if(total == null || total == 0){
        return 100;
    }

    return (puzzle.correct * 100 / total).toFixed(0);

}
async function get_daily_puzzle(){
    const current_date = new Date().toISOString().split("T")[0]

    const response = await fetch(app_server + `/puzzle/daily/${ current_date }/`, {
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        method: "GET",
        mode: "cors",
    });
    
    const puzzle_data = await response.json();
    
    const puzzles = Object.fromEntries(puzzle_data.puzzles.map( (x) => [x.date, x]));
    const equities = Object.fromEntries(puzzle_data.equities);

    const puzzle = puzzles[current_date];
    if(puzzle != null){
        dailypuzzle_data.puzzle = puzzle;
        dailypuzzle_data.equity = equities[current_date];
        dailypuzzle_data.percentage_correct = get_daily_puzzle_stats(puzzle);  
        dailypuzzle_data.solved = equities[current_date] != null;
        dailypuzzle_data.solves = puzzle.correct + puzzle.incorrect;
    }
}
</script>
<template>

<Header>
    <div v-if="userStore.authenticated"
        class="hidden md:grid grid-cols-3 gap-4 h-10 items-stretch px-2">
        <router-link :to="{name:'puzzle-menu'}">
            <button class="btn btn-blue h-full  w-full text-lg text-bold">
                Puzzle
            </button>
        </router-link>
        <router-link :to="{name:'match-menu'}">
            <button class="btn btn-blue h-full w-full text-lg text-bold"
                    :disabled="!matchStore.can_join_match"
            >
                Play
            </button>
        </router-link>
        <router-link :to="{name:'learn-menu'}">
            <button class="btn btn-blue h-full w-full text-lg text-bold">
                Learn
            </button>
        </router-link>
    </div>
    <div v-else>
        <router-link :to="{name:'frontpage'}">
            <button class="btn btn-blue h-full  w-full text-lg text-bold">
                Login
            </button>
        </router-link>
    </div>
</Header>

<JoinMatch />
<div class="flex flex-col relative w-screen">

<div class="block md:hidden grid grid-cols-3 gap-4 h-16 items-stretch my-6 px-2">
    <router-link :to="{name:'puzzle-menu'}"
            class="grow"
    >
        <button class="btn btn-blue text-lg text-bold w-full h-full">
            Puzzle
        </button>
    </router-link>
    <router-link :to="{name:'match-menu'}">
        <button class="btn btn-blue h-full w-full text-lg text-bold"
                    :disabled="!matchStore.can_join_match"
        >
            Play
        </button>
    </router-link>
    <router-link :to="{name:'learn-menu'}">
        <button class="btn btn-blue h-full w-full text-lg text-bold"
                    :disabled="!matchStore.can_join_match"
        >
            Learn
        </button>
    </router-link>
    <!---
    <span class="grow flex">
    <CreateMatch 
        v-if="userStore.info"
        @match-create="match_created">
    </CreateMatch>
    </span>
    --->
</div>

<div class="mt-4 flow-root p-2">
<div class="flex gap-x-6">
<div class="w-50 flex flex-col justify-start gap-y-8 hidden md:flex"> 
    <div>
    <router-link
        :to="{name: 'daily-puzzle', params: {}}"
    >
    <div class="cursor-pointer">
        <h2 class="text-2xl mb-4">Daily Puzzle</h2>
        <Board :positionString="dailypuzzle_data.puzzle.position" 
               :player_color="'W'" 
               :clock_data="{}"
               :show_pip_numbers="false"
               :show_away_default="true"
               boardID="dailypuzzle_big"
        >
        </Board>
        <Tooltip :text="`Solved correctly by ${dailypuzzle_data.percentage_correct}% of the users`"
              class="font-thin"
        >
        Solved by {{dailypuzzle_data.solves}} users
        </Tooltip>
    </div>
    </router-link>
    </div>
    <Leaderboard :inline="true"/>
</div>

<div class="flex flex-col grow relative overflow-hidden">

<div class="w-full md:hidden"
     v-if="!dailypuzzle_data.solved"
>
    <router-link
        :to="{name: 'daily-puzzle', params: {}}">
    <div class="cursor-pointer">
        <h2 class="text-2xl mb-4">Daily Puzzle</h2>
        <Board :positionString="dailypuzzle_data.puzzle.position" 
               :player_color="'W'" 
               :clock_data="{}"
               :show_pip_numbers="false"
               :show_away_default="true"
               boardID="dailypuzzle_mobile"
        >
        </Board>
        <Tooltip :text="`Solved correctly by ${dailypuzzle_data.percentage_correct}% of the users`"
              class="font-thin"
        >
        Solved by {{dailypuzzle_data.solves}} users
        </Tooltip>
    </div>
    </router-link>
</div>
<!--- TOURNAMENTS -->
<div v-if="(tournament_data.arena_list + tournament_data.upcoming_arena_list).length > 0"
    class="w-full overflow-x-hidden">
<h1 class="text-2xl flex gap-x-4 cursor-pointer">
    <span @click="extra_data.show_arena=true"
        :class="{
            'underline': extra_data.show_arena,
            'text-main-4-color hover:text-text-color': !extra_data.show_arena,
    }">
        Arenas
    </span>
    |
    <span @click="extra_data.show_arena=false"
        :class="{
            'underline': !extra_data.show_arena,
            'text-main-4-color hover:text-text-color': extra_data.show_arena,
    }">
        Tournaments (Beta)
    </span>
</h1>
<!--- ARENA LIST -->
<ul role="list" 
    class="flex gap-x-4 overflow-x-auto"
    v-if="extra_data.show_arena"
>
    <li v-for="tournament of tournament_data.upcoming_arena_list.concat(tournament_data.arena_list)" 
        :key="tournament.tournament_id" 
        class="flex gap-x-6 my-4 relative"
    >
        <span 
            class="relative box inline-flex flex-col justify-between 
                   rounded bg-main-2-color p-4"
            :class="{'!bg-accent-1-color' : tournament.active}"
        >
            <p class="font-semibold text-lg text-center">  
                {{ tournament.name }}
            </p>
            <div class="mx-auto my-4 text-center">
                <p v-if="tournament.before" class="font-thin text-lg">
                    Starting in
                </p>
                <p v-else class="font-thin text-lg">
                    Ends in
                </p>
                <p class="text-2xl tabular-nums">
                    {{ tournament.wait_time }}
                </p>
            </div>
            <div class="w-full flex gap-x-2 pt-2">
                <router-link class="flex-grow"
                    :to="{name: 'arena-info', params: {tournament_id: tournament.tournament_id}}">
                    <button class="btn btn-blue w-full h-10"
                            :disabled="!tournament.active"
                    >
                        Play
                    </button>
                </router-link>
                <router-link v-if="!tournament.active"
                    :to="{name: 'arena-info', params: {tournament_id: tournament.tournament_id}}">
                    <button class="btn btn-yellow w-full h-10">
                        Info
                    </button>
                </router-link>
            </div>
        </span>
        <span class="absolute flex size-5 -top-2 -right-2"
              v-if="matchStore.get_users_queued(tournament.tournament_id) > 0"
        >
            <span class="animate-ping absolute inline-flex h-full w-full 
                rounded-full bg-accent-4-color opacity-100">
            </span>
            <span class="relative inline-flex justify-center items-center 
                rounded-full h-5 w-5 bg-accent-3-color 
                text-center text-light-color"
            >
                {{ matchStore.get_users_queued(tournament.tournament_id) }}
            </span>
        </span>
    </li>
</ul>

<ul role="list" 
    class="flex gap-x-4 overflow-x-auto"
    v-else
>
    <li v-for="tournament of tournament_data.tournament_list" 
        :key="tournament.tournament_id" 
        class="flex gap-x-6 my-4 relative w-64"
    >
        <span 
            class="relative box flex flex-col justify-between 
                   rounded bg-main-2-color p-4 w-full"
            :class="{'!bg-accent-1-color' : tournament.is_registered}"
        >
            <p class="font-semibold text-lg text-center">  
                {{ tournament.name }}
            </p>
            <div class="mx-auto my-4 text-center">
                <p class="font-thin text-lg">
                    Registration ends:
                </p>
                <p class="text-2xl tabular-nums">
                    {{ tournament.wait_time }}
                </p>
                <p class="font-thin text-lg mt-4">
                    Registrations:
                </p>
                <p class="tabular-nums">
                    {{ tournament.registered }} / 
                    <span v-if="tournament.max_participants > 0">
                        {{ tournament.max_participants }}
                    </span>
                    <span v-else>
                        &infin;
                    </span>

                </p>
            </div>
            <div class="w-full flex gap-x-2 pt-2">
                <router-link class="w-full"
                    :to="{name: 'tournament-info', 
                          params: {tournament_id: tournament.tournament_id}}"
                >
                    <button class="btn btn-yellow w-full h-10"
                        v-if="!tournament.is_registered"
                    >
                        Register
                    </button>
                    <button class="btn btn-blue w-full h-10"
                        v-else
                    >
                        Info
                    </button>
                </router-link>
            </div>
        </span>
        <span class="absolute flex size-5 -top-2 -right-2"
              v-if="matchStore.get_users_queued(tournament.tournament_id) > 0"
        >
            <span class="animate-ping absolute inline-flex h-full w-full 
                rounded-full bg-accent-4-color opacity-100">
            </span>
            <span class="relative inline-flex justify-center items-center 
                rounded-full h-5 w-5 bg-accent-3-color 
                text-center text-light-color"
            >
                {{ matchStore.get_users_queued(tournament.tournament_id) }}
            </span>
        </span>
    </li>
</ul>
</div>

<!-- ACTIVE MATCH TABLE -->
<h1 class="text-2xl mb-2">Matches</h1>
<MatchList />
</div>
</div>
</div>
</div>

</template>

<style scoped>
</style>
