<script setup>

import {ref, watch, computed, reactive, onMounted, onUnmounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import {TrashIcon, CheckIcon, XMarkIcon} from '@heroicons/vue/24/solid'
import { Match } from '../assets/js/match.js'

import Tooltip from './Tooltip.vue'

const router = useRouter();

const app_server = import.meta.env.VITE_APP_SERVER;
const emit = defineEmits([
    'match-joined',
    'cancel',
]);

const props = defineProps(
    ['show', 'tournament_id']
);

var counter_interval_id = null;
var start_time;
var heartbeat_timeout = 60;

const extra_data = reactive({
    counter: 0,
    next_maintenance: null,
    duration: null,
    in_maintenance: false,
    offline: false,
});

onMounted(() => {
    check_for_maintenance();
    document.addEventListener("visibilitychange", () => {
        extra_data.background = Document.hidden;

        set_offline();
        check_for_maintenance();
        
        if(extra_data.background){
            clearInterval(counter_interval_id);
        }else{
            start_counter();
        }
    });
});

onUnmounted( () => {
    clearInterval(counter_interval_id);
});

watch(props, async (newProps, oldProps) => {
});

function start_counter(){
    clearInterval(counter_interval_id);

    if(extra_data.next_maintenance == null){ // no maintenance scheduled
        return;
    }
    extra_data.counter = parseInt((extra_data.next_maintenance - Date.now()) / 1000);
    counter_interval_id = setInterval(() => {
        set_offline();
        extra_data.counter = parseInt((extra_data.next_maintenance - Date.now()) / 1000);

        if(extra_data.counter < 0){
            set_maintenance(); 
            extra_data.counter = 0;
        }
    }, 2000);
}

async function check_for_maintenance(){

    if(extra_data.offline){
        return;
    }

    try{
        const response = await fetch(app_server + `/maintenance/`, {
            method: "GET",
            mode: "cors",
        });

        if(!response.ok){
            throw new Error(`Error while retrieving maintenance status: ${response.status}`);
        }

        const data = await response.json();

        stop_maintenance();
        extra_data.in_maintenance = false;
        extra_data.next_maintenance = data.next_maintenance * 1000;
        extra_data.duration = data.duration;
        start_counter();
    }catch(error){
        // If we reach an error state the app server is not reachable and we should
        // Start maintenance mode
        console.log(error);
        set_maintenance();
    }
}


function set_offline(){
    extra_data.offline = !navigator.onLine;
}

function set_maintenance(){
    extra_data.in_maintenance = true;

    // Disable all buttons on the site
    const buttons = document.getElementsByTagName("button");
    for(const button of buttons){
        if(!button.disabled){
            button.disabled = true;
            button.classList.add("maintenance");
        }
    }
}

function stop_maintenance(){
    extra_data.in_maintenance = false;

    // Disable all buttons on the site
    const buttons = document.getElementsByClassName("maintenance");
    for(const button of buttons){
        button.disabled = false;
        button.classList.remove("maintenance");
    }
}

function get_time(seconds){
    /*
    * returns a human reabable time string (bit overcomplicated, but wanted it to
    * be nicely formatted.
    */
    const months = Math.floor(seconds / (30*24*60*60)).toString();
    const days = Math.floor((seconds % (30*24*60*60)) / (24*60*60)).toString();
    const hours = Math.floor((seconds % (24*60*60)) / (60*60)).toString();
    const minutes = Math.floor((seconds % (60*60)) / 60).toString();
    const sec = Math.floor(seconds % 60).toString();

    var time = 0;
    var unit = ""

    if(months > 0){
        time = months;
        unit = `months`;
    }else if(days > 0){
        time = days;
        unit = `days`;
    }else if(hours > 0){
        time = hours;
        unit = `hours`;
    }else if(seconds >= 60){
        time = minutes;
        unit = `minutes`
    }else{
        time = sec; 
        unit = "seconds";
    }

    if(time == 1){
        unit = unit.slice(0, -1);
    }
    
    return `${time} ${unit}`;
}

</script>
<template>
    <div v-if="extra_data.next_maintenance || extra_data.in_maintenance || extra_data.offline">
        <span class="text-xl font-semibold flex justify-center items-center 
            gap-x-2 p-2"
              :class="{
                  'bg-error-color': !extra_data.offline && !extra_data.in_maintenance && extra_data.next_maintenance != null,
                  'bg-blunder-color text-stone-w-color': extra_data.in_maintenance || extra_data.offline,
                }"
        >
            <div v-if="extra_data.offline">
                You seem to be offline, please check your connection.
            </div>
            <div v-else-if="extra_data.in_maintenance">
                We are currently in Maintenance mode, check back in {{ get_time(extra_data.duration || 5*60) }}.
            </div>
            <div v-else>
                We will enter Maintenance mode in {{ get_time(extra_data.counter) }}.
            </div>
        </span>
    </div>
</template>

<style scoped>
</style>

