<script setup>
import {ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();
var tournament_id;

const json_fields = ["flairs", "structure_options", "participants", "disqualified", "invited", "moderators", "round_times"]; 
const date_fields = ["create_time", "start_time", "finish_time"]; 

const extra_data = reactive({
    tournament: {}
});


onMounted(() => {
    tournament_id = useRoute().params.tournament_id;
    load_tournament();
});

async function reload_tournament(){
    var url = app_server + `/admin/tournament/${tournament_id}/reload/`;

    const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const tournament_data = await response.json();
    console.log(tournament_data);
}

async function save_tournament(){
    var url = app_server + `/admin/tournament/${tournament_id}/`;
    const data = Object.assign({}, extra_data.tournament);

    for(let field of json_fields){
        data[field] = JSON.parse(data[field]);
    }
    for(let field of date_fields){
        data[field] = new Date(data[field]) / 1000;
    }


    const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify(data),
    });
    
    const tournament_data = await response.json();
    load_tournament();
}

async function load_tournament(){
    var url = app_server + `/admin/tournament/${tournament_id}/`;

    const response = await fetch(url,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const data = await response.json();
    console.log(data);

    if(data.tournament){
        extra_data.tournament = data.tournament;

        for(let field of json_fields){
            extra_data.tournament[field] = JSON.stringify(
                data.tournament[field], null, 4
            );
        }
        for(let field of date_fields){
            if(data.tournament[field] != null){
                const time_string = new Date(
                    (data.tournament[field] * 1000) -
                    ((new Date()).getTimezoneOffset()*60*1000)
                ).toISOString().slice(0,16);
                data.tournament[field] = time_string;
            }
        }
    }
}

async function finalize_tournament(){
    var url = app_server + `/admin/tournament/${tournament_id}/finalize/`;

    const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({}),
    });
    
    const tournament_data = await response.json();
    console.log("Done", tournament_data);
}

</script>

<template>
    <button class="btn btn-blue">
        <router-link :to="{name: 'admin-tournaments'}">
            Tournament List
        </router-link>
    </button>
    <button class="btn btn-red" @click="reload_tournament()">
        Reload
    </button>
    <!--    
    <button class="btn btn-red" @click="finalize_tournament()">
        Finalize
    </button>
    -->
    <button class="btn btn-red" v-if="tournament_id">
        <router-link :to="{name: 'tournament-info', params:{tournament_id: tournament_id}}">
            Tournament Info
        </router-link>
    </button>
    <h1>Tournament Detail</h1>
    <button @click="save_tournament()" class="btn btn-blue">Save</button>
    <form class="container flex flex-col max-w-prose mx-4">
        <div v-for="value,field in extra_data.tournament">
            <label :for="field"
                   class="block text-sm font-medium leading-6"
            >
                {{ field }}
            </label>
            
            <textarea 
                v-model="extra_data.tournament[field]"
                v-if="['description', 'rruleset', ...json_fields].includes(field)"
                :id="field"
                class="w-full"
            ></textarea>
            <input type="datetime-local" v-else-if="date_fields.includes(field)"
                v-model="extra_data.tournament[field]"
            >
            <input 
                class="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 
                        ring-inset ring-gray-300 placeholder:text-gray-400 
                        focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                v-model="extra_data.tournament[field]"
                v-else
                :id="field"
            />
        </div>
    </form>
</template>

<style>
</style>

